@import "https://fonts.googleapis.com/css2?family=Bungee&family=Poppins:wght@300;400;500;600;700&display=swap";

body {
  margin: 0px;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #16181e;
  overflow: hidden;
}

.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.custom-toast {
  background: linear-gradient(180deg, #f13737, #d80000, #ad0505) !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.wrapper:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(./assets/img/ludo_banner.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0.2;
}

.wrapper:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(./assets/img/ludo_banner.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  opacity: 0.2;
  transform: rotate(180deg);
}

.ludo-game {
  max-width: 1400px;
  width: 100%;
  position: relative;
  z-index: 9;
  margin: 0px auto;
}

.ludo-board {
  overflow: hidden;
  background: rgb(255 255 255);
  border-radius: 20px;
  width: 80.8vh;
  height: 80.8vh;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
  margin: 20px auto;
  transition: all 0.5s;
}

.test_controller {
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: #162031;
  background: url(./assets/img/blue.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #1a2436;
  border-radius: 20px 20px 0px 0px;
}

.logo-lv {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9;
  -webkit-filter: drop-shadow(2px 4px 6px #000);
  filter: drop-shadow(2px 4px 6px black);
}

.logo-lv img {
  width: 100%;
}

.start-game {
  max-width: 180px;
  width: 100%;
}

.start-game button {
  color: #000;
  text-transform: uppercase;
  padding: 10px 20px;
  width: 100%;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 0.15s ease-out, background 0.15s ease-out;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  font-family: "Bungee", cursive;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
}
.start-game button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ddd;
  border-radius: inherit;
  box-shadow: 0 0 0 2px #ddd, 0 0em 0 0 #ffe3e2;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
.start-game button:hover {
  background: #fff;
  transform: translate(0, 0.25em);
}
.start-game button:hover::before {
  /* box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2; */
  transform: translate3d(0, 0.5em, -1em);
}
.start-game button:active {
  background: #fff;
  transform: translate(0em, 0.75em);
}
.start-game button:active::before {
  /* box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2; */
  transform: translate3d(0, 0, -1em);
}

.bg-green {
  background-color: #00862b;
}

.bg-blue {
  background-color: #007fff;
}
.bg-red {
  background-color: #d80000;
}
.bg-yellow {
  background-color: #ffc900;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 1px;
}

.winner-home {
  background: rgb(237 237 237 / 30%);
  grid-column: 7/10;
  grid-row: 7/10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  border-radius: 20px;
  border: 1px solid #e2e0e0;
}
.winner-home:before {
  content: "";
  background: url(./assets/img/logo.png);
  width: 41px;
  height: 46px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
}
/* 
.winner-home .stack {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
}

.gwh.stack {
    border-color: rgb(0, 134, 43) transparent transparent;
    border-width: 75px 75px 0px;
}

.ywh.stack {
    border-color: rgb(255, 201, 0) transparent transparent;
    border-width: 75px 75px 0px;
    left: 37.5px;
    top: 37.5px;
    transform: rotate(90deg);
}
.bwh.stack {
    border-color: rgb(0 126 252) transparent transparent;
    border-width: 75px 75px 0px;
    top: 75px;
    transform: rotate(180deg);
}

.rwh.stack {
    border-color: rgb(216 0 0) transparent transparent;
    border-width: 75px 75px 0px;
    right: 37.5px;
    top: 37.5px;
    transform: rotate(-90deg);
} */

.red-home {
  box-shadow: inset 0 0 1px;
  grid-column: 1/7;
  grid-row: 1/7;
  background: linear-gradient(-90deg, #f13737, #d80000, #ad0505);
  position: relative;
}
.green-home {
  box-shadow: inset 0 0 1px;
  grid-column: 10/16;
  grid-row: 1/7;
  background: linear-gradient(90deg, #30ab58, #00862b, #015b1e);
  position: relative;
}
.blue-home {
  box-shadow: inset 0 0 1px;
  grid-column: 1/7;
  grid-row: 10/16;
  background: linear-gradient(-90deg, #3c8dd4, #007fff, #015daf);
  position: relative;
}

.yellow-home {
  box-shadow: inset 0 0 1px;
  grid-column: 10/16;
  grid-row: 10/16;
  background: linear-gradient(90deg, #f1d87f, #ffc900, #cfa300);
  position: relative;
}

.red-stop {
  background: url(./assets/img/stop.png);
  box-shadow: inset 0 0 1px;
  background-size: contain;
}
.green-stop {
  background: url(./assets/img/stop.png);
  box-shadow: inset 0 0 1px;
  background-size: contain;
}

.blue-stop {
  background: url(./assets/img/stop.png);
  box-shadow: inset 0 0 1px;
  background-size: contain;
}

.yellow-stop {
  background: url(./assets/img/stop.png);
  box-shadow: inset 0 0 1px;
  background-size: contain;
}

/* .red-stop-home{
    background: #d80000 url(./assets/img/stop.png);
    box-shadow: inset 0 0 1px;
    background-size: contain;
}

.green-stop-home{
    background: #00862b url(./assets/img/stop.png);
    box-shadow: inset 0 0 1px;
    background-size: contain;
}

.blue-stop-home{
    background: #007fff url(./assets/img/stop.png);
    box-shadow: inset 0 0 1px;
    background-size: contain;
}

.yellow-stop-home{
    background: #ffc900 url(./assets/img/stop.png);
    box-shadow: inset 0 0 1px;
    background-size: contain;
} */

.red-arrow,
.green-arrow,
.blue-arrow,
.yellow-arrow {
  position: relative;
}
.red-arrow:before {
  content: "";
  background: url(./assets/img/red-arrow.svg);
  background-size: 74%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 75%;
  height: 75%;
}

.green-arrow:before {
  content: "";
  background: url(./assets/img/green-arrow.svg);
  background-size: 74%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 75%;
  height: 75%;
  transform: rotate(90deg);
}

.blue-arrow:before {
  content: "";
  background: url(./assets/img/blue-arrow.svg);
  background-size: 74%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 75%;
  height: 75%;
  transform: rotate(-90deg);
}

.yellow-arrow:before {
  content: "";
  background: url(./assets/img/yellow-arrow.svg);
  background-size: 74%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 75%;
  height: 75%;
  transform: rotate(180deg);
}

.white-box {
  width: 80%;
  height: 80%;
  flex-wrap: wrap;
  /* border-radius: 100%;
    background-color: #fff;
    border: 5px solid rgba(0,0,0,.07); */
  transition: all 0.5s;
}

.player-avatar-grid {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.player-avatar {
  width: 90%;
  height: 90%;
  /*background: #fff;*/
  border-radius: 100%;
  overflow: hidden;
  margin: 0px auto;
  position: relative;
  cursor: pointer;
}

.player-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 9;
  background: #fff;
  border-radius: 100%;
}

.ludo-timer {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #00000000;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: rgb(0 0 0 / 30%);
  transition: stroke-dashoffset 2s ease 0s;
}

.emergency-timer .CircularProgressbar .CircularProgressbar-trail {
  stroke: #00000000;
}

.emergency-timer .CircularProgressbar .CircularProgressbar-path {
  stroke: rgb(185 4 4 / 30%);
  transition: stroke-dashoffset 2s ease 0s;
}

/* .red-home .CircularProgressbar .CircularProgressbar-path {
    stroke: #b9040480;
    transition: stroke-dashoffset 2s ease 0s;
  }

  .blue-home .CircularProgressbar .CircularProgressbar-path {
    stroke:  #0063bd80;
    transition: stroke-dashoffset 2s ease 0s;
  }

  .yellow-home .CircularProgressbar .CircularProgressbar-path {
    stroke: #d9aa0080;
    transition: stroke-dashoffset 2s ease 0s;
  }

  .green-home .CircularProgressbar .CircularProgressbar-path {
    stroke: #00611f80;
    transition: stroke-dashoffset 2s ease 0s;
  } */

/* .red-home .white-box.current-player{
	transform: scale(1.1);
}
.green-home .white-box.current-player{
	transform: scale(1.1);
}
.yellow-home .white-box.current-player{
	transform: scale(1.1);
}
.blue-home .white-box.current-player{
	transform: scale(1.1);
} */

.red_control.current-player-control {
  display: flex;
}
.green_control.current-player-control {
  display: flex;
}
.yellow_control.current-player-control {
  display: flex;
}
.blue_control.current-player-control {
  display: flex;
}

.player {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 1px solid black;
  transition: all 0.3s;
  /* filter: drop-shadow(0px 4px 4px #00000060); */
}
.player:hover {
  /* transform: scale(1.1); */
  opacity: 0.9;
  cursor: pointer;
}

.player-room .bg-red,
.step .bg-red,
.control .bg-red {
  background-color: #ad0606;
  border: 2px solid rgba(105, 4, 4, 0.56);
}

.player-room .bg-blue,
.step .bg-blue,
.control .bg-blue {
  background-color: #006ad4;
  border: 2px solid #064380;
}

.player-room .bg-yellow,
.step .bg-yellow,
.control .bg-yellow {
  background-color: #ddae00;
  border: 2px solid #a07e03;
}

.player-room .bg-green,
.step .bg-green,
.control .bg-green {
  background-color: #026822;
  border: 2px solid#034719;
}

.player-room {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.super-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-room .movered1,
.player-room .movegreen1,
.player-room .moveblue1,
.player-room .moveyellow1 {
  position: absolute;
  top: 10px;
  left: 10px;
}

.player-room .movered2,
.player-room .movegreen2,
.player-room .moveblue2,
.player-room .moveyellow2 {
  position: absolute;
  top: 10px;
  right: 10px;
}

.player-room .movered3,
.player-room .movegreen3,
.player-room .moveblue3,
.player-room .moveyellow3 {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.player-room .movered4,
.player-room .movegreen4,
.player-room .moveblue4,
.player-room .moveyellow4 {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* 
#dice{
    width:60px;
    height:60px;
    background-color:transparent;
    border:none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
	opacity: 1;
   
}
.dice{
    width:60px;
    height:60px;
}
.d0{
    background-image: url('./assets/img/dice0.png');
}
.d1{
    background-image: url('./assets/img/dice1.png');
}
.d2{
    background-image: url('./assets/img/dice2.png');
}
.d3{
    background-image: url('./assets/img/dice3.png');
}
.d4{
    background-image: url('./assets/img/dice4.png');
}
.d5{
    background-image: url('./assets/img/dice5.png');
}
.d6{
    background-image: url('./assets/img/dice6.png');
}

dice-disabled{
	opacity: 0.5;
} 
 
/********* New

.dice-grid {
    background: #c0c0c0;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    display: grid;
    padding: 10px;
    gap: 6px;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
    cursor: pointer;
}

.dice-grid span {
    background: #fff;
    border-radius: 100%;
    opacity: 0;
}

.dice-grid.dice1 span:nth-child(5){
    opacity: 1;
}

.dice-grid.dice2 span:nth-child(1), .dice-grid.dice2 span:nth-child(9){
    opacity: 1;
}

.dice-grid.dice3 span:nth-child(1), .dice-grid.dice3 span:nth-child(5) .dice-grid.dice3 span:nth-child(9){
    opacity: 1;
}

.dice-grid.dice4 span:nth-child(1), .dice-grid.dice4 span:nth-child(3), .dice-grid.dice4 span:nth-child(7) .dice-grid.dice4 span:nth-child(9){
    opacity: 1;
}


.dice-grid.dice5 span:nth-child(1), .dice-grid.dice5 span:nth-child(3), .dice-grid.dice5 span:nth-child(5) .dice-grid.dice5 span:nth-child(7), .dice-grid.dice5 span:nth-child(9){
    opacity: 1;
}

.dice-grid.dice6 span:nth-child(1), .dice-grid.dice6 span:nth-child(3), .dice-grid.dice6 span:nth-child(4) .dice-grid.dice6 span:nth-child(6), .dice-grid.dice6 span:nth-child(7), .dice-grid.dice6 span:nth-child(9){
    opacity: 1;
}
*/

.control .player:nth-child(2) {
  margin-right: 70px;
}

.control .player:nth-child(3) {
  margin-left: 70px;
}

.control {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.die-btn {
  position: relative;
  z-index: 9;
}
.dice-disabled {
  opacity: 0.8;
  pointer-events: none;
}

.dice-box {
  transform: scale(0.8);
}

#dice {
  width: 90px;
  height: 90px;
  transform-style: preserve-3d;
  transition: transform 0.7s ease-out;
}

#dice:hover {
  cursor: pointer;
}

.red-dice .sides {
  background: radial-gradient(
    circle at 100px 100px,
    rgb(86 5 5),
    rgb(217 12 12)
  ) !important;
  border: 5px solid rgb(155 0 0);
  border-radius: 16px
}

.yellow-dice .sides {
  background: radial-gradient(
    circle at 100px 100px,
    rgb(125 99 5),
    rgb(236 194 39)
  ) !important;
  border: 5px solid rgb(184 146 7);
  border-radius: 16px
}

.blue-dice .sides {
  background: radial-gradient(
    circle at 100px 100px,
    rgb(4 63 117),
    rgb(0 127 255)
  ) !important;
  border: 5px solid rgb(13 94 167);
  border-radius: 16px
}

.green-dice .sides {
  background: radial-gradient(
    circle at 100px 100px,
    rgb(4 78 28),
    rgb(36 162 77)
  ) !important;
  border: 5px solid rgb(3 123 42);
  border-radius: 16px
}

.sides {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
}

.dot {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
}

.side-1 .dot-1 {
  top: 50%;
  left: 50%;
}

.side-2 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-2 .dot-2 {
  top: 75%;
  left: 75%;
}

.side-3 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-3 .dot-2 {
  top: 75%;
  left: 75%;
}
.side-3 .dot-3 {
  top: 50%;
  left: 50%;
}

.side-4 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-4 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-4 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-4 .dot-4 {
  top: 75%;
  left: 75%;
}

.side-5 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-5 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-5 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-5 .dot-4 {
  top: 75%;
  left: 75%;
}
.side-5 .dot-5 {
  top: 50%;
  left: 50%;
}

.side-6 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-6 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-6 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-6 .dot-4 {
  top: 75%;
  left: 75%;
}
.side-6 .dot-5 {
  top: 50%;
  left: 25%;
}
.side-6 .dot-6 {
  top: 50%;
  left: 75%;
}

#dice .side-1 {
  transform: translateZ(45px);
}
#dice .side-2 {
  transform: rotateX(-180deg) translateZ(45px);
}
#dice .side-3 {
  transform: rotateY(90deg) translateZ(45px);
}
#dice .side-4 {
  transform: rotateY(-90deg) translateZ(45px);
}
#dice .side-5 {
  transform: rotateX(90deg) translateZ(45px);
}
#dice .side-6 {
  transform: rotateX(-90deg) translateZ(45px);
}

#dice[data-side="1"] {
  transform: rotateX(360deg) rotateY(360deg) rotateZ(720deg);
}
#dice[data-side="2"] {
  transform: rotateX(360deg) rotateY(540deg) rotateZ(720deg);
}
#dice[data-side="3"] {
  transform: rotateX(360deg) rotateY(630deg) rotateZ(720deg);
}
#dice[data-side="4"] {
  transform: rotateX(360deg) rotateY(450deg) rotateZ(720deg);
}
#dice[data-side="5"] {
  transform: rotateX(630deg) rotateY(360deg) rotateZ(720deg);
}
#dice[data-side="6"] {
  transform: rotateX(450deg) rotateY(360deg) rotateZ(720deg);
}

#dice[data-side="1"].reRoll {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
#dice[data-side="2"].reRoll {
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
}
#dice[data-side="3"].reRoll {
  transform: rotateX(0deg) rotateY(270deg) rotateZ(0deg);
}
#dice[data-side="4"].reRoll {
  transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
}
#dice[data-side="5"].reRoll {
  transform: rotateX(270deg) rotateY(0deg) rotateZ(0deg);
}
#dice[data-side="6"].reRoll {
  transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
}

/* *************** current player *************/

.current-player .moveyellow1,
.current-player .moveyellow2,
.current-player .moveyellow3,
.current-player .moveyellow4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.current-player .movered1,
.current-player .movered2,
.current-player .movered3,
.current-player .movered4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.current-player .moveblue1,
.current-player .moveblue2,
.current-player .moveblue3,
.current-player .moveblue4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.current-player .movegreen1,
.current-player .movegreen2,
.current-player .movegreen3,
.current-player .movegreen4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.active.moveyellow1,
.active.moveyellow2,
.active.moveyellow3,
.active.moveyellow4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.active.movered1,
.active.movered2,
.active.movered3,
.active.movered4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.active.moveblue1,
.active.moveblue2,
.active.moveblue3,
.active.moveblue4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.active.movegreen1,
.active.movegreen2,
.active.movegreen3,
.active.movegreen4 {
  animation: pulse 1s infinite ease-in-out alternate;
}

.current-player .player-avatar img {
  background: transparent;
}

.current-player .player-avatar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    60deg,
    #8e44ad,
    #9b59b6,
    #16a085,
    #1abc9c,
    #34495e
  );
  border-radius: 100%;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.active {
  z-index: 9;
}
@keyframes pulse {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/****************** Chat ******************/

.chat-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  /*display: none;*/
}

.chat-icon img {
  width: 50px;
  cursor: pointer;
  animation: pulse 1s infinite ease-in-out alternate;
}
.chat-box-wrapper {
  background: #1f222a;
  border-radius: 10px;
  bottom: 10px;
  box-shadow: -5px 17px 60px rgb(21 15 47 / 20%);
  height: 400px;
  max-width: 290px;
  position: fixed;
  right: 10px;
  width: 100%;
  z-index: 99;
  transition: all 0.5s;
  border: 1px solid #343434;
}
.chat-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  background: #16181e;
  border-radius: 10px 10px 0px 0px;
}
.chat-box-body {
  padding: 10px 15px;
}
.chat-box-header h6 {
  font-size: 18px;
  margin: 0;
  color: #ddd;
  font-weight: 600;
}
.chat-box-header span {
  cursor: pointer;
  color: #ddd;
}
.chat-box-header span i {
  font-size: 16px;
  font-weight: 600;
}
.chat-emoji-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 12px;
}
.chat-emoji-grid img {
  max-width: 50px;
  width: 100%;
  cursor: pointer;
}
.chat-text-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px dashed #434750;
}
.chat-text-grid span {
  background: #b9a11e;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.chat-text-grid span {
  background: #ffd800;
  border: 1px solid #b9a11e;
  box-shadow: 0px 2px 0 #b9a11e, 2px 4px 6px #6c6437;
}

.bubble-msg {
  background: #dadada;
  color: #000;
  font-size: 14px;
  padding: 5px;
  position: absolute;
  border-radius: 8px;
  max-width: 100px;
  left: 50%;
  width: 100%;
  z-index: 99999;
  font-weight: 400;
  bottom: 10px;
  text-align: center;
  transition: all 0.5s;
  transform: translate(-50%);
}

.triangle-isosceles {
  position: relative;
}
.triangle-isosceles:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid #dadada;
  border-bottom: 13px solid transparent;
  border-right: 13px solid transparent;
  position: absolute;
  /*left: 50%;*/
  top: -13px;
  transform: translate(-50%);
  transform: rotate(45deg);
}
.triangle-isosceles img {
  width: 40px;
}

/****************** Player Stats ******************/

.player-stats h4 {
  margin: 0;
  font-size: 14px;
  font-family: "Bungee", cursive;
  text-align: left;
  letter-spacing: 0.3px;
}

.player-stats ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
}

.player-stats {
  padding: 10px;
}

.player-stats ul li span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
}

.player-stats ul li {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding: 2px 0px;
  color: #ccc;
}

.player-stats-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
  gap: 10px;
}

.player-stats-user img {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 100%;
}

.player-stats-user-info img {
  width: 24px;
  height: auto;
  border-radius: 2px;
  position: absolute;
  left: 18px;
  bottom: 0;
}

.player-stats-user-info {
  width: calc(100% - 70px);
}

.user-ludo-score {
  font-size: 16px;
  padding-top: 10px;
  font-family: "Bungee", cursive;
  color: #000;
}

/********************* Winner Player  *******************/

.winner-player {
  position: relative;
}

.winner-player > .before,
.winner-player > .after {
  border-radius: 100%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  height: 6px;
  width: 6px;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  position: absolute;
}

.winner-player > .after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -29px -254.6666666667px #ff0026, 229px -254.6666666667px #ff002b,
      -203px 21.3333333333px #00ddff, 248px -408.6666666667px #1eff00,
      -221px -190.6666666667px #00d0ff, 191px -336.6666666667px #0037ff,
      -128px -17.6666666667px #eaff00, 9px -131.6666666667px #00eeff,
      -153px -228.6666666667px #ff9500, -123px -238.6666666667px #ff2f00,
      -155px -38.6666666667px #ff009d, 82px -32.6666666667px #6fff00,
      136px -352.6666666667px #c4ff00, 21px 82.3333333333px #ff00ee,
      245px -219.6666666667px #ff0040, 125px -357.6666666667px #1100ff,
      226px -135.6666666667px #00d5ff, -116px -388.6666666667px #ff007b,
      66px -221.6666666667px #00ff4d, -80px -52.6666666667px #55ff00,
      -162px -223.6666666667px #00ff2f, 119px -360.6666666667px #ffee00,
      -83px 67.3333333333px #00ffc8, 27px -349.6666666667px #0084ff,
      -23px -41.6666666667px #ff006f, -193px 13.3333333333px #0026ff,
      210px -400.6666666667px #ff5100, 114px -40.6666666667px #ffd000,
      -194px -73.6666666667px #0055ff, 58px -108.6666666667px #0095ff,
      -137px -339.6666666667px #66ff00, -240px -196.6666666667px #00ff51,
      109px -256.6666666667px #00ffee, -168px -51.6666666667px #00f2ff,
      -48px -262.6666666667px #ff00c4, -21px -344.6666666667px #b3ff00,
      -214px -173.6666666667px #5900ff, -155px -304.6666666667px #ff9d00,
      -71px 52.3333333333px #a2ff00, -134px -172.6666666667px #00bbff,
      -140px 58.3333333333px #ff0084, 72px -263.6666666667px #dd00ff,
      -151px -240.6666666667px #ff00e1, -34px -153.6666666667px #6f00ff,
      -171px -254.6666666667px #00ffea, -164px -2.6666666667px #ff0044,
      76px -120.6666666667px #00c8ff, -181px -255.6666666667px #ff6200,
      -51px -263.6666666667px yellow, -62px -367.6666666667px #d000ff,
      10px -388.6666666667px #ff0009, 2px -371.6666666667px #00ffc4,
      -144px -330.6666666667px #ffc400, -113px 15.3333333333px #0055ff,
      140px -351.6666666667px #4dff00, 10px -102.6666666667px #ff006a,
      155px -299.6666666667px #8800ff, -98px -7.6666666667px #ff0d00,
      -184px -261.6666666667px #cc00ff, 240px -97.6666666667px #ff5500,
      -99px -106.6666666667px #00a2ff;
  }
}
@keyframes bang {
  to {
    box-shadow: -29px -254.6666666667px #ff0026, 229px -254.6666666667px #ff002b,
      -203px 21.3333333333px #00ddff, 248px -408.6666666667px #1eff00,
      -221px -190.6666666667px #00d0ff, 191px -336.6666666667px #0037ff,
      -128px -17.6666666667px #eaff00, 9px -131.6666666667px #00eeff,
      -153px -228.6666666667px #ff9500, -123px -238.6666666667px #ff2f00,
      -155px -38.6666666667px #ff009d, 82px -32.6666666667px #6fff00,
      136px -352.6666666667px #c4ff00, 21px 82.3333333333px #ff00ee,
      245px -219.6666666667px #ff0040, 125px -357.6666666667px #1100ff,
      226px -135.6666666667px #00d5ff, -116px -388.6666666667px #ff007b,
      66px -221.6666666667px #00ff4d, -80px -52.6666666667px #55ff00,
      -162px -223.6666666667px #00ff2f, 119px -360.6666666667px #ffee00,
      -83px 67.3333333333px #00ffc8, 27px -349.6666666667px #0084ff,
      -23px -41.6666666667px #ff006f, -193px 13.3333333333px #0026ff,
      210px -400.6666666667px #ff5100, 114px -40.6666666667px #ffd000,
      -194px -73.6666666667px #0055ff, 58px -108.6666666667px #0095ff,
      -137px -339.6666666667px #66ff00, -240px -196.6666666667px #00ff51,
      109px -256.6666666667px #00ffee, -168px -51.6666666667px #00f2ff,
      -48px -262.6666666667px #ff00c4, -21px -344.6666666667px #b3ff00,
      -214px -173.6666666667px #5900ff, -155px -304.6666666667px #ff9d00,
      -71px 52.3333333333px #a2ff00, -134px -172.6666666667px #00bbff,
      -140px 58.3333333333px #ff0084, 72px -263.6666666667px #dd00ff,
      -151px -240.6666666667px #ff00e1, -34px -153.6666666667px #6f00ff,
      -171px -254.6666666667px #00ffea, -164px -2.6666666667px #ff0044,
      76px -120.6666666667px #00c8ff, -181px -255.6666666667px #ff6200,
      -51px -263.6666666667px yellow, -62px -367.6666666667px #d000ff,
      10px -388.6666666667px #ff0009, 2px -371.6666666667px #00ffc4,
      -144px -330.6666666667px #ffc400, -113px 15.3333333333px #0055ff,
      140px -351.6666666667px #4dff00, 10px -102.6666666667px #ff006a,
      155px -299.6666666667px #8800ff, -98px -7.6666666667px #ff0d00,
      -184px -261.6666666667px #cc00ff, 240px -97.6666666667px #ff5500,
      -99px -106.6666666667px #00a2ff;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
.position-image {
  position: absolute;
  z-index: 99;
  bottom: 15px;
}

.position-image img {
  max-width: 70px;
}

/**************************** Winner Player *******************************/

.winner-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  max-width: 400px;
  width: 100%;
  background: url(./assets/img/win-bg.png);
  height: 537px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winner-popup::before {
  content: "";
  background: url(./assets/img/win-strip.png);
  position: absolute;
  width: 500px;
  height: 155px;
  margin: 0px auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 9999;
  display: block;
  top: 0;
}

.winner-overlay::before {
  content: "";
  background: #000000d4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 99;
}

.winner-popup-content {
  position: relative;
  background: url(./assets/img/win-bg-content.png);
  max-width: 360px;
  width: 100%;
  height: 496px;
  margin: 0px auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  padding-top: 80px;
}

.win-star img {
  max-width: 180px;
  margin: 0px auto;
  display: block;
}

.win-btns button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0px 8px;
}

.win-btns button img {
  height: 50px;
}

.win-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.win-players {
  padding: 20px 0px 10px;
  max-width: 300px;
  margin: 0px auto;
}

.win-players ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.win-player-user-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.win-player-user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  background: #eddec5;
  padding: 3px;
}

.win-player-user h4 {
  margin: 0;
  padding-left: 10px;
  font-weight: 700;
  color: #222;
  font-size: 15px;
}

.win-player-user {
  display: flex;
  align-items: center;
}

.win-players li {
  background: #dcc0a2;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px 20px 5px 20px;
  position: relative;
  padding-left: 30px;
}

.win-players li:nth-child(1):before {
  content: "";
  background: url(./assets/img/gold.png);
  width: 30px;
  height: 38px;
  background-size: cover;
  position: absolute;
  top: 8px;
  left: -8px;
}

.win-players li:nth-child(2):before {
  content: "";
  background: url(./assets/img/silver.png);
  width: 30px;
  height: 38px;
  background-size: cover;
  position: absolute;
  top: 8px;
  left: -8px;
}

.win-players li:nth-child(3):before {
  content: "";
  background: url(./assets/img/bronze.png);
  width: 30px;
  height: 38px;
  background-size: cover;
  position: absolute;
  top: 8px;
  left: -8px;
}

.win-players li:nth-of-type(odd) {
  border-radius: 20px 5px 20px 5px;
}

.win-player-content {
  font-size: 16px;
  color: #c96f38;
  font-family: "Bungee", cursive;
}

/**************************** Old Css *******************************/

.step .player {
  position: absolute;
}

.step .player:nth-child(1) {
  margin-top: 0px;
  margin-left: 0px;
}
.step .player:nth-child(2) {
  margin-top: 3px;
  margin-left: 3px;
}
.step .player:nth-child(3) {
  margin-top: 6px;
  margin-left: 6px;
}
.step .player:nth-child(4) {
  margin-top: 6px;
  margin-left: 6px;
}
.step .player:nth-child(5) {
  margin-top: 9px;
  margin-left: 9px;
}
.step .player:nth-child(6) {
  margin-top: 12px;
  margin-left: 12px;
}

.step .player:nth-child(7) {
  margin-top: 15px;
  margin-left: 15px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba( 208, 2, 27, 0.4 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px ); */
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: large;
  display: inline-block;
}
.control button {
  width: 45px;
  height: 45px;
}

.lobby {
  padding: 20px 40px;
}
.control3 button {
  /* margin-top:15px; */
  width: 40px;
  height: 40px;
}
.control3 .lb {
  margin-top: 15px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: larger;
}

.f {
  display: flex;

  align-items: center;
}

.movered1,
.movegreen1,
.moveblue1,
.moveyellow1 {
  background-image: url(./assets/img/bat.png);
  /* background-image: url(./assets/img/1.gif); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.cun {
  border-radius: 10px;
  border: 1px solid black;
  padding: 0px 5px;
}
.bot {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
}
.botoff {
  background-image: url(./assets/img/nobot.png);
}
.boton {
  background-image: url(./assets/img/bot.png);
}
.playersel {
  background-image: url(./assets/img/bat.png);

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.movered2,
.movegreen2,
.moveblue2,
.moveyellow2 {
  background-image: url(./assets/img/frog.png);
  /* background-image: url(./assets/img/2.gif); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.movered3,
.movegreen3,
.moveblue3,
.moveyellow3 {
  background-image: url(./assets/img/panda.png);
  /* background-image: url(./assets/img/3.gif); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.movered4,
.movegreen4,
.moveblue4,
.moveyellow4 {
  background-image: url(./assets/img/tiger.png);
  /* background-image: url(./assets/img/4.gif); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
/* #dice{
font-size: 50px;
margin:5px;
padding:10px;
font-weight: bold;
} */
.icon {
  /* width:16px; */
  height: 15px;
}
.icon2 {
  height: 30px;
}
.topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* flex-direction: column;
     */

  gap: 20px;
}

.gameover {
  width: 100vw;
  height: 100vh;
  z-index: +999;
  position: absolute;
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.29);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}

.welcome {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: +999;
  position: absolute;
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.29);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.9px);
  -webkit-backdrop-filter: blur(7.3px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}
.menu {
  max-width: 95vw;
  background: rgba(255, 255, 255, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border: 1px solid rgba(255, 255, 255, 0.66);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* transform: scale(1.5);   */
}
.scoreboard {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border: 1px solid rgba(255, 255, 255, 0.66);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(1.5);
}
.scoreboard .playerlist {
  display: flex;
  gap: 10px;
  align-items: center;
}
.chatscreen {
  box-sizing: border-box;
  padding: 20px;

  width: 50vh;
  /* height:50vh; */
  /* From https://css.glass */
  /* From https://css.glass */
  /* background: rgba(255, 255, 255, 0.5);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(7.4px);
-webkit-backdrop-filter: blur(7.4px);
border: 1px solid rgba(255, 255, 255, 0.3); */
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 20px;
}

.user_msg {
  /* From https://css.glass */
  background: rgba(19, 75, 243, 0.33);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(19, 75, 243, 0.3);
  padding: 7px;
  font-size: 20px;
  width: 70%;
}
.user_msg2 {
  /* From https://css.glass */
  background: rgba(19, 75, 243, 0.33);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(19, 75, 243, 0.3);
  padding: 7px;
  font-size: 20px;
}

.send_btn {
  border: none;
  background-color: transparent;
  background-image: url(./assets/img/right-arrow.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}
.send_btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

.stack {
  position: relative;
}

.stack .player {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#host_btn {
  margin: auto;
  /* From https://css.glass */
  background: rgba(255, 1, 1, 0.41);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(255, 1, 1, 0.3);
  font-size: larger;
  margin-top: 20px;
  padding: 6px 10px;
  cursor: pointer;
}
#start_btn {
  opacity: 0.7;
  color: white;
  margin: auto;
  /* From https://css.glass */
  background: rgba(15, 66, 235, 0.726);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(8, 62, 240, 0.712);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(18, 23, 59, 0.3);
  font-size: larger;
  margin-top: 20px;
  padding: 6px 10px;
  cursor: pointer;
}
#loading_btn {
  margin: auto;
  /* From https://css.glass */
  /* background: rgba(255, 1, 1, 0.41);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    border: 1px solid rgba(255, 1, 1, 0.3); */
  /* font-size: larger; */
  margin-top: 20px;
  /* padding:6px 10px; */
  /* cursor:pointer; 
     */
  background-color: transparent;
  border: none;
}
#host_btn:hover {
  opacity: 0.8;
  transform: scale(1.1);
}
.control2 {
  margin-top: 20px;
  justify-content: space-around;
}
.stack .player:nth-child(1) {
  margin-top: 4px;
  margin-left: 0;
}
.stack .player:nth-child(2) {
  margin-top: 0px;
  margin-left: 0;
}
.stack .player:nth-child(3) {
  margin-top: -4px;
  margin-left: 0;
}
.stack .player:nth-child(4) {
  margin-top: -8px;
  margin-left: 0;
}

.red_control {
  display: none;
}
.green_control {
  display: none;
}
.yellow_control {
  display: none;
}
.blue_control {
  display: none;
}

/************ Winner Section **********/
.red-home .white-box.winner-rank-1 {
  background-image: url(assets/img/crown1.png);
  opacity: 1;
}
.red-home .white-box.winner-rank-2 {
  background-image: url(assets/img/crown2.png);
  opacity: 1;
}
.red-home .white-box.winner-rank-3 {
  background-image: url(assets/img/crown3.png);
  opacity: 1;
}

.green-home .white-box.winner-rank-1 {
  background-image: url(assets/img/crown1.png);
  opacity: 1;
}
.green-home .white-box.winner-rank-2 {
  background-image: url(assets/img/crown2.png);
  opacity: 1;
}
.green-home .white-box.winner-rank-3 {
  background-image: url(assets/img/crown3.png);
  opacity: 1;
}

.yellow-home .white-box.winner-rank-1 {
  background-image: url(assets/img/crown1.png);
  opacity: 1;
}
.yellow-home .white-box.winner-rank-2 {
  background-image: url(assets/img/crown2.png);
  opacity: 1;
}
.yellow-home .white-box.winner-rank-3 {
  background-image: url(assets/img/crown3.png);
  opacity: 1;
}

.blue-home .white-box.winner-rank-1 {
  background-image: url(assets/img/crown1.png);
  opacity: 1;
}
.blue-home .white-box.winner-rank-2 {
  background-image: url(assets/img/crown2.png);
  opacity: 1;
}
.blue-home .white-box.winner-rank-3 {
  background-image: url(assets/img/crown3.png);
  opacity: 1;
}

.game-timer {
  text-align: left;
  color: #fff;
  font-size: 20px;
  font-family: "Bungee", cursive;
  position: absolute;
  left: 20px;
  top: 20px;
}

/********************************************* Media Query **************************************************************/
/********************************************* Media Query **************************************************************/

@media screen and (max-width: 1680px) {
  .player {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (max-width: 1366px) {
  .player {
    width: 3.5rem;
    height: 3.5rem;
  }
  .start-game {
    max-width: 140px;
  }

  .start-game button {
    padding: 8px 20px;
    font-size: 14px;
  }
  .test_controller {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    align-items: center;
  }

  .ludo-board {
    width: 70vh;
    height: 70vh;
  }

  .logo-lv {
    top: 20px;
    left: 50%;
    bottom: auto;
    transform: translateX(-50%);
  }

  .test_controller {
    background: transparent;
    border: none;
  }

  .player {
    width: 2.5rem;
    height: 2.5rem;
    filter: none;
  }

  .winner-home:before {
    width: 40px;
    height: 27px;
  }
  .player-stats {
    padding: 5px;
  }
  .player-stats-user {
    margin-bottom: 8px;
  }
  .player-stats-user img {
    width: 40px;
    height: 40px;
  }
  .player-stats-user-info img {
    width: 20px;
    left: 10px;
    height: auto;
  }
  .player-stats-user-info {
    width: calc(100% - 50px);
  }
  .player-stats h4 {
    font-size: 10px;
  }

  .player-stats ul li,
  .player-stats ul li span {
    font-size: 12px;
  }
  .position-image img {
    max-width: 40px;
  }
  .chat-box-wrapper {
    height: 260px;
  }
  .chat-box-header {
    padding: 8px 15px;
  }
  .chat-box-header h6 {
    font-size: 12px;
  }
  .chat-emoji-grid {
    column-gap: 15px;
  }
  .chat-emoji-grid img {
    max-width: 30px;
  }
  .chat-text-grid span {
    font-size: 12px;
  }
  .triangle-isosceles img {
    width: 30px;
  }
  .bubble-msg {
    max-width: 80px;
  }

  .winner-popup {
    width: 98%;
    height: 510px;
  }
  .winner-popup-content {
    max-width: 94%;
    width: 94%;
    height: 470px;
  }
  .winner-popup::before {
    width: 340px;
    height: 106px;
  }
  .win-players {
    max-width: 280px;
  }
  .win-player-user h4 {
    padding-left: 5px;
    font-size: 13px;
  }
  .win-player-content {
    font-size: 14px;
  }
  .win-btns button img {
    height: 45px;
  }
  .win-star img {
    max-width: 160px;
  }
  .win-player-user img {
    width: 35px;
    height: 35px;
  }
  .win-players li:nth-child(1):before,
  .win-players li:nth-child(2):before,
  .win-players li:nth-child(3):before {
    top: 5px;
  }
  .user-ludo-score {
    font-size: 12px;
    padding-top: 5px;
  }
  .game-timer {
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    left: inherit;
    top: inherit;
  }
}

@media screen and (max-width: 667px) {
  .ludo-board {
    width: 60vh;
    height: 60vh;
  }
}

@media screen and (max-width: 560px) {
  .ludo-board {
    width: 55vh;
    height: 55vh;
  }
}

@media screen and (max-width: 480px) {
  .ludo-board {
    width: 50vh;
    height: 50vh;
  }
  .dice-box {
    transform: scale(0.6);
  }

  .control button {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 414px) {
  .ludo-board {
    width: 45vh;
    height: 45vh;
  }
}

@media screen and (max-width: 393px) {
  .ludo-board {
    width: 50vh;
    height: 50vh;
  }
  .player-room .movered1,
  .player-room .movegreen1,
  .player-room .moveblue1,
  .player-room .moveyellow1 {
    top: 5px;
    left: 5px;
  }
  .player-room .movered2,
  .player-room .movegreen2,
  .player-room .moveblue2,
  .player-room .moveyellow2 {
    top: 5px;
    right: 5px;
  }
  .player-room .movered3,
  .player-room .movegreen3,
  .player-room .moveblue3,
  .player-room .moveyellow3 {
    bottom: 5px;
    left: 5px;
  }
  .player-room .movered4,
  .player-room .movegreen4,
  .player-room .moveblue4,
  .player-room .moveyellow4 {
    bottom: 5px;
    right: 5px;
  }
}

@media screen and (max-width: 320px) {
  .ludo-board {
    width: 60vh;
    height: 60vh;
  }
  .logo-lv img {
    width: 60px;
  }

  .winner-popup {
    width: 98%;
    height: 460px;
  }
  .winner-popup-content {
    width: 94%;
    height: 440px;
    padding-top: 50px;
  }
  .winner-popup::before {
    width: 300px;
    height: 90px;
  }
  .win-players {
    max-width: 250px;
  }
}
